import React from 'react'
import { Routes, Route } from 'react-router-dom';
import ListShopbyColor from './ListShopbyColor';

import EditShopbyColor from './EditShopbyColor';
import AddShopbyColor from './AddShopbyColor';

export default function ShopbyColorRouter() {
    return (
        <Routes>
            <Route path='/shopbycolor' element={<ListShopbyColor />} />
            <Route path='/shopbycolor-add' element={<AddShopbyColor />} />
            <Route path='/shopbycolor-edit/:_id' element={<EditShopbyColor />} />

        </Routes>
    )
}
