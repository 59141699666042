import React from 'react'
import { Routes, Route } from 'react-router-dom';
import ListShopbyRoom from './ListShopbyRoom';
import AddShopbyRoom from './AddShopbyRoom';
import EditShopbyRoom from './EditShopbyRoom';

export default function ShopbyRoomRoutes() {
    return (
        <Routes>
            <Route path='/shopbyroom' element={<ListShopbyRoom />} />
            <Route path='/shopbyroom-add' element={<AddShopbyRoom />} />
            <Route path='/shopbyroom-edit/:_id' element={<EditShopbyRoom />} />

        </Routes>
    )
}
