import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { OrderContext } from '../../store/OrderContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/banner.css';


export default function ListOrder() {
    const [order, setOrder] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage] = useState(10); // Change this value as needed
    const [searchQuery, setSearchQuery] = useState('');
    const { getOrder, } = useContext(OrderContext);

    // Modal state management
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalAction, setModalAction] = useState('');
    const [confirmItem, setConfirmItem] = useState(null);

    // Get all data
    async function getAPIData(page) {
        const response = await getOrder(page, itemsPerPage, searchQuery);
        console.log(response)
        if (response.result === "Done") {
            setOrder(response.data.docs);
            setTotalPages(response.data.totalPages);
        } else {
            alert(response.message);
        }
    }

    // Handle delete action



    // Handle status change
    // const handleStatusChange = (item) => {
    //     setConfirmItem(item);
    //     setModalAction('status');
    //     setShowConfirmModal(true);
    // };

    // const confirmStatusChange = async () => {
    //     if (confirmItem) {
    //         const newStatus = confirmItem.is_active === true ? false : true;
    //         await orderStatus(confirmItem._id, newStatus);
    //         getAPIData(currentPage);
    //         setShowConfirmModal(false);
    //         toast.success('Order status changed successfully', {
    //             position: "top-right",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //     }
    // };

    // Pagination
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    // Search
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        getAPIData(currentPage);
    };
    // Handle Drag and Drop




    useEffect(() => {
        getAPIData(currentPage);
        const toastr = localStorage.getItem('toast');
        if (toastr) {
            toast.success(toastr, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            localStorage.removeItem('toast');
        }
    }, [currentPage]);

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <ToastContainer />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>Order - List</h3>
                                </div>
                                <div className="flexbox mb-4">
                                    <div className="input-group-icon input-group-icon-left mr-3">
                                        <br />
                                        <form onSubmit={handleSearchSubmit} className="search-container">
                                            <input className="form-control form-control-rounded form-control-solid" id="key-search"
                                                type="text" placeholder="Search Order..." value={searchQuery}
                                                onChange={handleSearchChange} />
                                        </form>
                                    </div>
                                </div>

                            </div>
                            <div className="ibox-body">

                                <div className="table-responsive row">
                                    <table className="table table-bordered table-hover text-center" id="datatable">
                                        <thead className="thead-default thead-lg">
                                            <tr>
                                                <th>Sr No</th>
                                                {/* <th>Image</th> */}
                                                <th>Order Number</th>
                                                <th>Order Date</th>
                                                <th>Amount Info</th>
                                                <th>Mode Of Payment</th>
                                                <th>Order Status</th>
                                                <th>Status</th>
                                                <th className="no-sort">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {order.length > 0 ? order.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                    {/* <td><img src={item.profile_image} width="90px" height="70px" alt="Customer" /></td> */}
                                                    <td>{item.order_number} </td>

                                                    <td>
                                                        <b>Added On</b>: {item.createdAt ? new Date(item.createdAt).toLocaleDateString('en-GB') : ''}
                                                        <br /> <b>Updated On </b>: {item.updatedAt ? new Date(item.updatedAt).toLocaleDateString('en-GB') : ''}
                                                    </td>
                                                    <td><b>Total: </b>{(item.amount_info.total_amount_paid_by_customer)}
                                                        <br /><b>Discounted: </b>{item.amount_info.discounted_amount}
                                                        <br /><b>Sub-total: </b>{item.amount_info.sub_total}
                                                        <br /><b>Tax-amount: </b>{item.amount_info.tax_amount}
                                                        <br /><b>Tax-percent: </b>{item.amount_info.tax_percent}
                                                        <br /><b>Refund-Amount: </b>{item.amount_info.refund_amount}
                                                        <br /><b>Total-Order-Amount: </b>{item.amount_info.total_order_amount}

                                                    </td>
                                                    <td><b>Payment Method: </b>{(item.payment_info.payment_method)}</td>
                                                    <td>{item.order_status}</td>
                                                    <td>
                                                        {item.is_active === true ? (
                                                            <span className="badge badge-success">Active</span>
                                                        ) : (
                                                            <span className="badge badge-danger">Inactive</span>
                                                        )}
                                                    </td>
                                                    <td className="text-center">

                                                    </td>

                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td colSpan={9}>No Records Found</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    <button className="btn btn-primary" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                    <span className="pagination-info">{`Page ${currentPage} of ${totalPages}`}</span>
                                    <button className="btn btn-primary" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

            {/* Modal for Confirmation */}
            {/* <div className={`modal fade ${showConfirmModal ? 'show d-block' : ''}`} tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{modalAction === 'delete' ? 'Confirm Order Deletion' : 'Confirm Status Update'}</h5>
                            <button type="button" className="btn-close" onClick={() => setShowConfirmModal(false)}></button>
                        </div>
                        <div className="modal-body">
                            <p>{modalAction === 'delete' ? 'Are you sure you want to delete this order?' : 'Are you sure you want to update the status?'}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmModal(false)}>Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={modalAction === 'delete' ? confirmDelete : confirmStatusChange}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}
