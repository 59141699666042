import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { ShopbyRoomContext } from '../../store/ShopbyRoomContextProvider';
import { useParams, useNavigate } from 'react-router-dom';

export default function EditShopbyRoom() {

    // var [name, setname] = useState("")
    const [errors, setErrors] = useState({});
    const [imageError, setImageError] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    var navigate = useNavigate()
    var { _id } = useParams()

    const [shopbyroom, setShopbyRoom] = useState({
        name: "",
        url: "",
        description: "",
        image: null
    });



    var { getShopbyRoomById, ShopbyRoomUpdate } = useContext(ShopbyRoomContext)

    function getData(e) {
        const { name, value } = e.target;

        setShopbyRoom((oldData) => ({
            ...oldData,
            [name]: value
        }));
    }

    function getFile(e) {
        const file = e.target.files[0];
        if (file) {
            if (!file.type.startsWith('image/')) {
                setImageError("File must be an image");
                return;
            }
            if (file.size > 2 * 1024 * 1024) { // Set limit to 2MB
                setImageError("Image size should be less than 2MB");
                return;
            }
            setImageError("");
            setShopbyRoom((oldData) => ({
                ...oldData,
                image: file
            }));
        }
    }

    function validateFields() {
        let tempErrors = {};
        if (!shopbyroom.name) tempErrors.name = "Name is required";
        if (!shopbyroom.url) tempErrors.url = "URL is required";
        if (!shopbyroom.description) tempErrors.description = "Description is required";
        if (!shopbyroom.image) tempErrors.image = "Image is required";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    }

    async function postData(e) {
        e.preventDefault();

        if (!validateFields()) return;

        setIsLoading(true); // Show loader
        setIsButtonDisabled(true); // Disable button

        const response = await ShopbyRoomUpdate(shopbyroom, _id);
        console.log(response);


        if (response.result.status === 200) {
            localStorage.setItem('toast', 'ShopbyRoom has Update successfully');
            navigate("/shopbyroom");
        }
        else
            alert(response.message);
        setIsLoading(false); // Hide loader
        setIsButtonDisabled(false); // Enable button
        // Implement the logic to update the banner
        // const response = await updateBanner(banner); // Assuming you have an updateBanner function
        // if (response.result.status === 200) navigate("/banner");
        // else alert(response.message);

        // Remove this line once you implement the update logic
    }


    async function getAPIData() {
        if (!_id) {
            console.error('ID is undefined or null');
            return;
        }

        var item = {
            _id: _id
        };
        // console.log(item);

        try {
            var response = await getShopbyRoomById(item);
            console.log(response.data.data.docs[0], "hfdskjhfdjksfdsjkf>>>>>>>>>>>>>");

            setShopbyRoom(response.data.data.docs[0]);
        } catch (error) {
            console.error('Error fetching shopbyroom:', error);
        }
    }



    useEffect(() => {
        getAPIData()
    }, [])
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>ShopbyRoom - Edit</h3>
                                </div>
                                <Link to="/shopbyroom" className="btn btn-secondary pull-right">Back</Link>
                            </div>
                            <div className="ibox-body">
                                <form method="post" onSubmit={postData}>
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="name" className="control-label">Name<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name="name" onChange={getData} value={shopbyroom.name} maxLength="100" />
                                                {errors.name && <small className="text-danger">{errors.name}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="url" className="control-label">URL<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name="url" onChange={getData} value={shopbyroom.url} maxLength="100" />
                                                {errors.url && <small className="text-danger">{errors.url}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="image" className="control-label">Image<span className="text-danger">*</span></label>
                                                <input type="file" className="form-control" name="image" onChange={getFile} />
                                                {imageError && <small className="text-danger">{imageError}</small>}
                                                {errors.image && <small className="text-danger">{errors.image}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="description" className="control-label">Description<span className="text-danger">*</span></label>
                                                <textarea id="description" className="form-control" name="description" onChange={getData} value={shopbyroom.description} maxLength="100"></textarea>
                                                {errors.description && <small className="text-danger">{errors.description}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-1">
                                            <Link to="/shopbyroom" className="btn btn-secondary pull-right">Back</Link>
                                        </div>
                                        <div className="col-md-8 text-center">
                                            <div className="form-group">
                                                <button className="btn btn-primary btn-rounded btn-block" type="submit" disabled={isButtonDisabled}>
                                                    {isLoading ? 'ShopbyRoom Updating...' : 'Update ShopbyRoom'}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
