import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { CategoryContext } from '../../store/CategoryContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/banner.css';

export default function ListCategory() {
    const [category, setCategory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const { getCategory, deleteCategory, categoryStatus, updateCategorySort } = useContext(CategoryContext);

    // Drag-and-drop state
    const [draggedItem, setDraggedItem] = useState(null);

    // State for confirmation modals
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalAction, setModalAction] = useState('');
    const [confirmItem, setConfirmItem] = useState(null);

    // Fetch data
    async function getAPIData(page) {
        var response = await getCategory(page, itemsPerPage, searchQuery);
        if (response.result === "Done") {
            setCategory(response.data.docs);
            setTotalPages(response.data.totalPages);
        } else {
            alert(response.message);
        }
    }

    // Handle delete
    const handleDelete = (item) => {
        setConfirmItem(item);
        setModalAction('delete');
        setShowConfirmModal(true);
    };

    const confirmDelete = async () => {
        if (confirmItem) {
            await deleteCategory({ _id: confirmItem._id });
            getAPIData(currentPage);
            setShowConfirmModal(false);
            toast.success('Category deleted successfully', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    // Handle status change
    const handleStatusChange = (item) => {
        setConfirmItem(item);
        setModalAction('status');
        setShowConfirmModal(true);
    };

    const confirmStatusChange = async () => {
        if (confirmItem) {
            const newStatus = confirmItem.is_active === 1 ? 0 : 1;
            await categoryStatus(confirmItem._id, newStatus);
            getAPIData(currentPage);
            setShowConfirmModal(false);
            toast.success('Category status changed successfully', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    // Handle feature addition
    const handleFeatureCategory = (item) => {
        setConfirmItem(item);
        setModalAction('feature');
        setShowConfirmModal(true);
    };

    const confirmFeatureCategory = async () => {
        if (confirmItem) {
            const newStatus = confirmItem.is_feature === 1 ? 0 : 1;
            await categoryStatus(confirmItem._id, { feature: newStatus });
            getAPIData(currentPage);
            setShowConfirmModal(false);
            toast.success('Category marked as featured successfully', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    // Pagination
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    // Search
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setSearchTerm(searchQuery);
        getAPIData(1);
    };

    // Drag-and-Drop
    const handleDragEnd = async (result) => {
        if (!result.destination) return;

        const reorderedCategory = [...category];
        const [removed] = reorderedCategory.splice(result.source.index, 1);
        reorderedCategory.splice(result.destination.index, 0, removed);

        const updatedCategory = reorderedCategory.map((item, index) => ({
            ...item,
            sort_no: index + 1,
        }));
        setCategory(updatedCategory);
        await updateCategorySort(updatedCategory);
        toast.success("Category position updated successfully");
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getAPIData(currentPage);

        const toastr = localStorage.getItem('toast');
        if (toastr) {
            toast.success(toastr, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            localStorage.removeItem('toast');
        }
    }, [currentPage]);

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <ToastContainer />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>Category - List</h3>
                                </div>
                                <div className="flexbox mb-4">
                                    <div className="input-group-icon input-group-icon-left mr-5">
                                        <span className="input-icon input-icon-right font-16"><i className="ti-search"></i></span><br />
                                        <form onSubmit={handleSearchSubmit} className="search-container">
                                            <input className="form-control form-control-rounded form-control-solid" id="key-search" type="text" placeholder="Search Category..." value={searchQuery} onChange={handleSearchChange} />
                                        </form>
                                    </div>
                                </div>
                                <Link to="/category-add" className="btn btn-primary pull-right">Add New Record</Link>
                            </div>
                            <div className="ibox-body">

                                <div className="table-responsive row">
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <table className="table table-bordered table-hover text-center" id="datatable">
                                            <thead className="thead-default thead-lg">
                                                <tr>
                                                    <th style={{ width: '5%' }}>Sr No</th>
                                                    <th style={{ width: '15%' }}>Image</th>
                                                    <th style={{ width: '10%' }}>Category</th>
                                                    <th style={{ width: '20%' }}>Category Name</th>
                                                    <th style={{ width: '30%' }}>Description</th>
                                                    <th style={{ width: '5%' }}>Sort</th>
                                                    <th style={{ width: '5%' }}>Status</th>
                                                    <th style={{ width: '10%' }} className="no-sort">Action</th>
                                                </tr>
                                            </thead>

                                            <Droppable droppableId="categories">
                                                {(provided) => (
                                                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                                        {category.filter(item => item.name !== "Root").map((item, index) => (
                                                            <Draggable
                                                                key={item._id}
                                                                draggableId={item._id}
                                                                index={index}
                                                            >
                                                                {(provided) => (
                                                                    <tr
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <td>{index + 1}</td>
                                                                        <td><img src={item.image} width="130px" height="110px" alt="Category" /></td>
                                                                        <td>{item.parentCategoryName || "N/A"}</td>
                                                                        <td>{item.name}</td>
                                                                        <td>
                                                                            {item.description?.slice(0, 100)}...
                                                                        </td>
                                                                        <td>{item.sort_no}</td>
                                                                        <td>
                                                                            <span
                                                                                className={`badge ${item.is_active
                                                                                    ? "badge-success"
                                                                                    : "badge-danger"
                                                                                    }`}
                                                                            >
                                                                                {item.is_active ? "Active" : "Inactive"}
                                                                            </span>
                                                                        </td>
                                                                        <td className="text-center">

                                                                            <Link
                                                                                to={`/edit-category/${item._id}`}
                                                                                className="badge badge-primary badge-pill"
                                                                                style={{
                                                                                    display: 'block',
                                                                                    marginBottom: '5px',
                                                                                    cursor: 'pointer',
                                                                                    textDecoration: 'none' // Added style for removing underline
                                                                                }}
                                                                            >
                                                                                Edit Category
                                                                            </Link>

                                                                            <span className="badge badge-danger badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleDelete(item)}>
                                                                                Delete Category
                                                                            </span>

                                                                            {item.is_active === 1 ? (
                                                                                <span className="badge bg-warning text-dark badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleStatusChange(item)}>
                                                                                    Deactivate Status
                                                                                </span>
                                                                            ) : (
                                                                                <span className="badge bg-secondary badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleStatusChange(item)}>
                                                                                    Activate Status
                                                                                </span>
                                                                            )}


                                                                            {item.is_feature === 1 ? (
                                                                                <span className="badge bg-secondary badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleFeatureCategory(item)}>
                                                                                    Remove Feature Category
                                                                                </span>
                                                                            ) : (
                                                                                <span className="badge badge-info badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleFeatureCategory(item)}>
                                                                                    Add Feature Category
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </tbody>
                                                )}
                                            </Droppable>

                                            {/* <tbody>
                                                {category.length > 0 ? category.filter(item => item.name !== "Root").map((item, index) => (
                                                    <tr>
                                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                        <td><img src={item.image} width="130px" height="110px" alt="Category" /></td>
                                                        <td>{item.parentCategoryName}</td>
                                                        <td>{item.name}</td>
                                                        <td>
                                                            {item.description && item.description.length > 150
                                                                ? `${item.description.substring(0, 150)}...`
                                                                : item.description || "No description available"}
                                                        </td>
                                                        <td>{item.sort_no}</td>
                                                        <td>
                                                            {item.is_active === 1 ? (
                                                                <span className="badge badge-success badge-pill">Active</span>
                                                            ) : (
                                                                <span className="badge badge-danger badge-pill">Inactive</span>
                                                            )}
                                                        </td>
                                                        <td className="text-center">

                                                            <Link
                                                                to={`/edit-category/${item._id}`}
                                                                className="badge badge-primary badge-pill"
                                                                style={{
                                                                    display: 'block',
                                                                    marginBottom: '5px',
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'none' // Added style for removing underline
                                                                }}
                                                            >
                                                                Edit Category
                                                            </Link>

                                                            <span className="badge badge-danger badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleDelete(item)}>
                                                                Delete Category
                                                            </span>

                                                            {item.is_active === 1 ? (
                                                                <span className="badge bg-warning text-dark badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleStatusChange(item)}>
                                                                    Deactivate Status
                                                                </span>
                                                            ) : (
                                                                <span className="badge bg-secondary badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleStatusChange(item)}>
                                                                    Activate Status
                                                                </span>
                                                            )}


                                                            {item.is_feature === 1 ? (
                                                                <span className="badge bg-secondary badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleFeatureCategory(item)}>
                                                                    Remove Feature Category
                                                                </span>
                                                            ) : (
                                                                <span className="badge badge-info badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleFeatureCategory(item)}>
                                                                    Add Feature Category
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )) : (
                                                    <tr>
                                                        <td colSpan="8">No categories found.</td>
                                                    </tr>
                                                )}
                                            </tbody> */}
                                        </table>
                                    </DragDropContext>
                                    <nav>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                                            </li>
                                            {Array.from({ length: totalPages }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => handlePageChange(i + 1)}>{i + 1}</button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            {/* Modal for Confirmations */}
            <div className={`modal fade ${showConfirmModal ? 'show d-block' : ''}`} tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{modalAction === 'delete' ? 'Confirm Category Deletion' : modalAction === 'feature' ? 'Confirm Add Feature Category' : 'Confirm Status Update'}</h5>
                            <button type="button" className="btn-close" onClick={() => setShowConfirmModal(false)}></button>
                        </div>
                        <div className="modal-body">
                            <p>{modalAction === 'delete' ? 'Are you sure you want to delete this category?' : modalAction === 'feature' ? 'Are you sure you want to mark this category as featured?' : 'Are you sure you want to update the status?'}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmModal(false)}>
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary" onClick={modalAction === 'delete' ? confirmDelete : modalAction === 'feature' ? confirmFeatureCategory : confirmStatusChange}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
