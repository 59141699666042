import React from 'react'
import { Routes, Route } from 'react-router-dom';
import ListOrder from './ListOrder';

export default function OrderRoutes() {
    return (
        <Routes>
            <Route path='/orders' element={<ListOrder />} />


        </Routes>
    )
}
