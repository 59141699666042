import React, { createContext } from 'react'
import axios from 'axios';
import { ADMIN_BASE_URL } from '../utils/config'
export const ShopbyRoomContext = createContext()


async function addShopbyRoom(shopbyroom) {

    var item = new FormData();
    item.append('name', shopbyroom.name);
    item.append('url', shopbyroom.url);
    item.append('image', shopbyroom.image);
    item.append('description', shopbyroom.description);

    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
    } else {
        let config = {
            headers: {
                "content-type": "multipart/form-data",
                "authorization": `Bearer ${token}`
            }
        };

        try {
            let result = await axios.post(`${ADMIN_BASE_URL}/shopbyroom`, item, config);
            console.log('Success:', result);
            if (result)
                return { result }
            else
                return { result: "Fail", message: "Internal Server Error" }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    }
}




async function getShopbyRoom(page, itemsPerPage, searchQuery) {
    let config2 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },

    }
    var rawdata = await fetch(`${ADMIN_BASE_URL}/shopbyroom?keyword=${searchQuery}&status=&page_no=${page}&limit=${itemsPerPage}`, config2)
    // var rawdata = await fetch(`${ADMIN_BASE_URL}/banners?keyword=Summer%20Sale&status=1&page_no=1&limit=10`, config2)
    var result = await rawdata.json()

    if (result)
        return { result: "Done", data: result.data }
    else
        return { result: "Fail", message: "Internal Server Error" }
}




async function getShopbyRoomById(item) {
    let config3 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };

    try {
        let response = await fetch(`${ADMIN_BASE_URL}/shopbyroom/${item._id}`, config3);

        let result = await response.json();

        if (result) {
            let { id, ...x } = result;
            return { result: "Done", data: { _id: id, ...x } };
        } else {
            return { result: "Fail", message: "Internal Server Error" };
        }
    } catch (error) {
        return { result: "Fail", message: error.message };
    }
}



async function ShopbyRoomUpdate(shopbyroom, _id) {

    var item = new FormData();

    item.append('name', shopbyroom.name);
    item.append('url', shopbyroom.url);
    item.append('image', shopbyroom.image);
    item.append('description', shopbyroom.description);

    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
    } else {
        let config = {
            headers: {
                "content-type": "multipart/form-data",
                "authorization": `Bearer ${token}`
            }
        };

        try {
            let result = await axios.put(`${ADMIN_BASE_URL}/shopbyroom/${_id}`, item, config);
            console.log('Success:', result);
            if (result)
                return { result }
            else
                return { result: "Fail", message: "Internal Server Error" }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    }
}



async function deleteShopbyRoom(item) {
    let config3 = {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };


    var rawdata = await fetch(`${ADMIN_BASE_URL}/shopbyroom/${item._id}`, config3)
    var result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "ShopbyRoom is Deleted" }
}



async function ShopbyRoomStatus(_id, status) {

    let config3 = {
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };
    let obj = {
        is_active: status
    }
    console.log(obj)

    var result = await axios.put(`${ADMIN_BASE_URL}/shopbyroom/status/${_id}`, obj, config3)
    // var result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "ShopbyRoom is Deleted" }
}


async function updateShopbyRoomSort(shopbyroom) {
    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
        return { result: "Fail", message: "Token not found" };
    } else {
        let config = {
            headers: {
                "Content-Type": "application/json", // Updated for JSON format
                "Authorization": `Bearer ${token}` // Ensure correct header for token authentication
            }
        };

        try {
            // Sending updated category data with the corresponding _id
            let result = await axios.put(`${ADMIN_BASE_URL}/shopbyroom/drag/sort`, { shopbyroom }, config);

            // Check if the response is successful
            if (result.status === 200 && result.data.success) {
                console.log('Success:', result);
                return { result: "Success", message: "Banners order updated successfully" };
            } else {
                return { result: "Fail", message: result.data.message || "Internal Server Error" };
            }
        } catch (error) {
            // Handle different error cases to provide detailed logging and messaging
            if (error.response) {
                // The request was made and the server responded with a status code outside the 2xx range
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);

            return { result: "Fail", message: "Error updating shopbyroom order" };
        }
    }
}


export default function ShopbyRoomContextProvider(props) {
    return <ShopbyRoomContext.Provider value={
        {
            addShopbyRoom: addShopbyRoom,
            getShopbyRoom: getShopbyRoom,
            getShopbyRoomById: getShopbyRoomById,
            ShopbyRoomUpdate: ShopbyRoomUpdate,
            deleteShopbyRoom: deleteShopbyRoom,
            ShopbyRoomStatus: ShopbyRoomStatus,
            updateShopbyRoomSort: updateShopbyRoomSort
        }
    }>
        {props.children}
    </ShopbyRoomContext.Provider>
}
