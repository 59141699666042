import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import ReactQuill from 'react-quill';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { CategoryContext } from '../../store/CategoryContextProvider';
import { ProductContext } from '../../store/ProductContextProvider';
import axios from 'axios';

import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom'


export default function ProductAdd() {
    let navigate = useNavigate();
    const { getCategory } = useContext(CategoryContext);
    const { addProduct, getSkuExists } = useContext(ProductContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [skuExists, setSkuExists] = useState(null); // For success or error message


    const ALLOWED_FILE_TYPES = /jpeg|jpg|png|gif|avif|tiff|bmp|webp|svg|heic/i;
    const MAX_FILE_SIZE_MB = 2;

    const [formData, setFormData] = useState({
        name: '',
        category_id: '',
        sub_category_id: '',
        sub_product_category_id: '',
        short_description: '',
        long_description: '',
        image: '',
        secondary_images: [],
        video_url: '',
        product_url: '',
        sku: '',
        tax: '',
        exchange_policy: '',
        return_policy: '',
        is_replacement: '',
        is_refund: '',
        meta_title: '',
        meta_description: '',
        meta_keywords: '',
        focus_keyword: '',

    });

    const [errors, setErrors] = useState({});
    const [categories, setCategories] = useState([]);
    const [Allcategories, setAllCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [childCategories, setChildCategories] = useState([]);


    const generateProductUrl = (name) => {
        return name
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-') // Replace spaces and special characters with hyphens
            .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        let newFormData = { ...formData };
        // const files = event.target.files;
        let fileErrors = '';

        if (files && files.length) {
            Array.from(files).forEach((file) => {
                const fileType = file.type.split('/')[1];
                const fileSizeMB = file.size / (1024 * 1024);

                if (!ALLOWED_FILE_TYPES.test(fileType)) {
                    fileErrors = `Invalid file type: ${fileType}. Allowed types are jpeg, jpg, png, gif, tiff, bmp, webp, svg, heic.`;
                } else if (fileSizeMB > MAX_FILE_SIZE_MB) {
                    fileErrors = `File size should not exceed ${MAX_FILE_SIZE_MB} MB.`;
                }
            });
        }

        if (fileErrors) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: fileErrors }));
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
            setFormData((prevData) => ({ ...prevData, [name]: files }));
        }
        if (type === 'file') {
            if (name === 'image') {
                newFormData[name] = files[0];
            } else if (name === 'secondary_images') {
                newFormData[name] = Array.from(files);
            }
        } else {
            newFormData[name] = value;

            // Automatically update Product URL based on Product Name
            if (name === 'name') {
                newFormData['product_url'] = generateProductUrl(value);
            }

            // Validate SKU length and perform debounce SKU check
            if (name === 'sku') {
                if (value.length < 8) {
                    setErrors({ ...errors, sku: 'SKU must be at least 8 characters long.' });
                    setSkuExists(null); // Reset SKU check if the length is invalid
                } else if (value.length >= 8 && value.length <= 20) {
                    setErrors({ ...errors, sku: null }); // Clear error if SKU length is valid
                    console.log('calling debounceCheckSKU >>>>>>>>>>>>>>', value);
                    debounceCheckSKU(value); // Call debounce function to check SKU availability
                } else {
                    setErrors([{ ...errors, sku: 'SKU must be a maximum of 20 characters long.' }]);
                    setSkuExists(null); // Reset SKU check if the length exceeds the limit
                }
            }


        }

        setFormData(newFormData);
    };
    // Debounce function to reduce frequent API calls
    const debounceCheckSKU = debounce(async (sku) => {
        console.log('calling debounceCheckSKU >>>>>>>>>>>>>>', sku)

        if (sku.length > 8) {
            try {
                const response = await getSkuExists(sku);
                // Set the state based on API response
                if (response.data.status) {
                    setSkuExists(true); // SKU already exists
                    setErrors({ sku: 'SKU Code already exists' });
                } else {
                    setSkuExists(false); // SKU is available
                    setErrors({ sku: null });
                }
            } catch (error) {
                // console.error('Error checking SKU:', error);
                setErrors({ sku: 'SKU Code already exists' });
            }
        } else {
            setSkuExists(null); // Reset if input is cleared
        }
    }, 500); // Delay of 500ms

    // Debounce function
    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    }

    const handleLongDescriptionChange = (content) => {
        setFormData((prevState) => ({
            ...prevState,
            long_description: content,
        }));
    };

    const handleNumericChange = (e) => {
        const { name, value } = e.target;
        if (!isNaN(value) || value === '') {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateFields()) {

            setIsLoading(true); // Show loader
            setIsButtonDisabled(true); // Disable button

            const formDataToSubmit = new FormData();
            // Append form data fields
            formDataToSubmit.append('name', formData.name);
            formDataToSubmit.append('category_id', formData.category_id);
            formDataToSubmit.append('sub_category_id', formData.sub_category_id);
            formDataToSubmit.append('sub_product_category_id', formData.sub_product_category_id);
            formDataToSubmit.append('short_description', formData.short_description);
            formDataToSubmit.append('long_description', formData.long_description);
            formDataToSubmit.append('video_url', formData.video_url);
            formDataToSubmit.append('product_url', formData.product_url);
            formDataToSubmit.append('sku', formData.sku);
            formDataToSubmit.append('tax', formData.tax);
            // formDataToSubmit.append('exchange_policy', formData.exchange_policy);
            // formDataToSubmit.append('return_policy', formData.return_policy);
            formDataToSubmit.append('is_refund', formData.is_refund);
            formDataToSubmit.append('is_replacement', formData.is_replacement);
            formDataToSubmit.append('meta_description', formData.meta_description);
            formDataToSubmit.append('meta_title', formData.meta_title);
            formDataToSubmit.append('focus_keyword', formData.focus_keyword);
            formDataToSubmit.append('meta_keywords', formData.meta_keywords);

            // Append main image file
            if (formData.image) {
                formDataToSubmit.append('image', formData.image);
            }

            // Append secondary images
            if (formData.secondary_images.length > 0) {
                formData.secondary_images.forEach((file) => {
                    formDataToSubmit.append('secondary_images', file);
                });
            }

            // Log the formData content (optional for debugging)
            // for (let pair of formDataToSubmit.entries()) {
            //     // console.log(pair[0] + ':', pair[1]);
            // }
            setIsLoading(true); // Hide loader
            setIsButtonDisabled(true); // Enable button
            // Send form data to addProduct API
            const response = await addProduct(formDataToSubmit);
            // console.log(response.result.data.data._id);
            // console.log(response);

            if (response.result.status === 200) {
                localStorage.setItem('toast', 'Product has been created successfully');
                navigate(`/product-attributes-add/${response.result.data.data._id}`);
            } else {
                console.log(response.result)
                // toast.error(response.result.data.message);
                alert(response.result.response.data);
                console.log(errors)
                setErrors({ ...errors, product_add: response.result.response.data });
                setIsLoading(false); // Hide loader
                setIsButtonDisabled(false); // Enable button

            }
        }
    };


    const validateFields = () => {
        let tempErrors = {};
        if (!formData.name) tempErrors.name = "Product name is required";
        if (!formData.product_url) tempErrors.product_url = "Product URL is required";
        if (!formData.sku) tempErrors.sku = "Product SKU code is required";
        if (!formData.tax) tempErrors.tax = "Tax is required";
        // if (!formData.exchange_policy) tempErrors.exchange_policy = "Exchange Policy is required";
        // if (!formData.return_policy) tempErrors.return_policy = "Return Policy is required";
        if (!formData.is_refund) tempErrors.is_refund = "Refund Type is required";
        if (!formData.is_replacement) tempErrors.is_replacement = "Replacement Type is required";
        if (!formData.category_id) tempErrors.category_id = "Category ID is required";
        if (!formData.short_description) tempErrors.short_description = "Short description is required";
        if (!formData.long_description) tempErrors.long_description = "Long description is required";
        if (!formData.image) tempErrors.image = "Image is required";
        if (formData.secondary_images.length > 5) tempErrors.secondary_images = "You can upload a maximum of 5 secondary images";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };
    // Fetch categories and subcategories

    const fetchCategories = async () => {
        try {
            let response = await getCategory(1, 50, '');
            // console.log('response', response);
            if (response.result == 'Done') {
                let categories = response.data.docs
                setAllCategories(categories)
                let filteredCategories = response.data.docs.filter(category => category.parent_id == '66ad2ac0a4c6b61fd84863b8'); // need to change on live if parent_id change
                setCategories(filteredCategories); // Assuming the API returns a 'categories' array
            } else {
                // console.error('Failed to fetch categories');
            }
        } catch (error) {
            // console.error('Error:', error);
        }
    };
    // Handle category change and filter subcategories
    const handleCategoryChange = (e) => {
        const selectedCategoryId = e.target.value;

        setFormData({ ...formData, category_id: selectedCategoryId });
        // Assuming subcategories are part of the category data
        const selectedCategory = Allcategories.filter(cat => {
            return cat.parent_id == selectedCategoryId
        });

        if (selectedCategory) {
            setSubCategories(selectedCategory || []);
        } else {
            setSubCategories([]);
        }
    };

    const handleSubCategoryChange = (e) => {
        const selectedCategoryId = e.target.value;
        setFormData({ ...formData, sub_category_id: e.target.value });
        const selectedSubCategory = Allcategories.filter(cat => {
            return cat.parent_id == selectedCategoryId
        });

        if (selectedSubCategory) {
            setChildCategories(selectedSubCategory || []);
        } else {
            setChildCategories([]);
        }

    };

    const handleChildCategoryChange = (e) => {
        const selectedCategoryId = e.target.value;

        setFormData({ ...formData, child_category_id: selectedCategoryId });
    }
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        fetchCategories();
    }, []);
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Sidebar />

                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>Product - Add</h3>
                                </div>
                                <Link to="/product-list" className="btn btn-secondary pull-right">
                                    Back To Product
                                </Link>
                            </div>
                            <div className="ibox-body">
                                <Form onSubmit={handleSubmit}>
                                    {/* Card for Category Info */}
                                    <div className="card shadow mb-4">
                                        <div className="card-header bg-success text-white">Category Info</div>
                                        <div className="card-body">
                                            <Row className="mb-3">
                                                <Col md={4}>
                                                    <Form.Group controlId="formCategoryId">
                                                        <Form.Label>Category <span style={{ color: 'red' }}>*</span></Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            name="category_id"
                                                            value={formData.category_id}
                                                            onChange={handleCategoryChange}
                                                            isInvalid={!!errors.category_id}
                                                        >
                                                            <option value="">Select Category</option>
                                                            {categories.map(category => (
                                                                <option key={category._id} value={category._id}>
                                                                    {category.name}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.category_id}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group controlId="formSubCategoryId">
                                                        <Form.Label>Product Category</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            name="sub_category_id"
                                                            value={formData.sub_category_id}
                                                            onChange={handleSubCategoryChange}
                                                            disabled={!subCategories.length}
                                                        >
                                                            <option value="">Select Sub-Category</option>
                                                            {subCategories.map(subCategory => (
                                                                <option key={subCategory._id} value={subCategory._id}>
                                                                    {subCategory.name}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group controlId="formSubCategoryId">
                                                        <Form.Label>Product Sub Category</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            name="sub_product_category_id"
                                                            value={formData.sub_product_category_id}
                                                            onChange={handleChildCategoryChange}
                                                            disabled={!childCategories.length}
                                                        >
                                                            <option value="">Select Sub-Category</option>
                                                            {childCategories.map(childCategory => (
                                                                <option key={childCategory._id} value={childCategory._id}>
                                                                    {childCategory.name}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    {/* Card for Product Details */}
                                    <div className="card shadow mb-4">
                                        <div className="card-header bg-success text-white">Product Details</div>
                                        <div className="card-body">
                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group controlId="formName">
                                                        <Form.Label>Product Name <span style={{ color: 'red' }}>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="name"
                                                            value={formData.name}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.name}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.name}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="formProductUrl">
                                                        <Form.Label>Product URL <span style={{ color: 'red' }}>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="product_url"
                                                            value={formData.product_url}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.product_url}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.product_url}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group controlId="formSku">
                                                        <Form.Label>
                                                            Product SKU Code <span style={{ color: 'red' }}>*</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="sku"
                                                            value={formData.sku}
                                                            onChange={(e) => {
                                                                const value = e.target.value.toUpperCase();
                                                                if (value.length <= 20) {
                                                                    setFormData({ ...formData, sku: value });
                                                                }
                                                                handleChange(e);
                                                            }}
                                                            isInvalid={!!errors.sku}
                                                            maxLength="20" // Max length
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.sku}
                                                        </Form.Control.Feedback>
                                                        {/* Show success or error message based on SKU check */}
                                                        {skuExists === true && (
                                                            <small style={{ color: 'red' }}>SKU Code already exists.</small>
                                                        )}
                                                        {skuExists === false && (
                                                            <small style={{ color: 'green' }}>SKU Code is available.</small>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group controlId="formTax">
                                                        <Form.Label>Tax (%) <span style={{ color: 'red' }}>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="tax"
                                                            value={formData.tax}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.tax}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.tax}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            {/* <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group controlId="formReturnPolicy">
                                                        <Form.Label>
                                                            Return Policy <span style={{ color: 'red' }}>*</span>
                                                        </Form.Label>
                                                        <Form.Select
                                                            name="return_policy"
                                                            value={formData.return_policy}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.return_policy}
                                                        >
                                                            <option value="">Select Return Policy</option>
                                                            <option value="30 days return">30 Days Return</option>
                                                            <option value="No returns accepted">No Returns Accepted</option>
                                                            <option value="Exchange only">Exchange Only</option>
                                                            <option value="Refund within 14 days">Refund Within 14 Days</option>
                                                            <option value="Store credit only">Store Credit Only</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.return_policy}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group controlId="formTax">
                                                        <Form.Label>Exhange & Return Condition <span style={{ color: 'red' }}>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="exchange_policy"
                                                            value={formData.exchange_policy}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.exchange_policy}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.exchange_policy}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row> */}
                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group controlId="formReturnPolicy">
                                                        <Form.Label>
                                                            Is Replacement <span style={{ color: 'red' }}>*</span>
                                                        </Form.Label>
                                                        <Form.Select
                                                            name="is_replacement"
                                                            value={formData.is_replacement}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.is_replacement}
                                                        >
                                                            <option value="">-- Select Replacemnet Type --</option>
                                                            <option value="true">YES</option>
                                                            <option value="false">NO</option>

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.is_replacement}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group controlId="formReturnPolicy">
                                                        <Form.Label>
                                                            Is Refund <span style={{ color: 'red' }}>*</span>
                                                        </Form.Label>
                                                        <Form.Select
                                                            name="is_refund"
                                                            value={formData.is_refund}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.is_refund}
                                                        >
                                                            <option value="">-- Select Refund Type --</option>
                                                            <option value="true">YES</option>
                                                            <option value="false">NO</option>

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.is_refund}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group controlId="formImage">
                                                        <Form.Label>
                                                            Product Image <span style={{ color: 'red' }}>*</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="file"
                                                            name="image"
                                                            accept="image/*"
                                                            onChange={(e) => handleChange(e, 'image')}
                                                            isInvalid={!!errors.image}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.image}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="formSecondaryImages">
                                                        <Form.Label>Secondary Images (Max 5)</Form.Label>
                                                        <Form.Control
                                                            type="file"
                                                            name="secondary_images"
                                                            accept="image/*"
                                                            multiple
                                                            onChange={(e) => handleChange(e, 'secondary_images')}
                                                            isInvalid={!!errors.secondary_images}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.secondary_images}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    {/* Card for Product Descriptions */}
                                    <div className="card shadow mb-4">
                                        <div className="card-header bg-success text-white">Product Descriptions</div>
                                        <div className="card-body">
                                            <Row className="mb-3">
                                                <Col md={12}>
                                                    <Form.Group controlId="formShortDescription">
                                                        <Form.Label>Short Description <span style={{ color: 'red' }}>*</span></Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            name="short_description"
                                                            value={formData.short_description}
                                                            onChange={handleChange}
                                                            rows={3}
                                                            isInvalid={!!errors.short_description}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.short_description}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col md={12}>
                                                    <Form.Group controlId="formLongDescription">
                                                        <Form.Label>Long Description <span style={{ color: 'red' }}>*</span></Form.Label>
                                                        <ReactQuill
                                                            value={formData.long_description}
                                                            onChange={handleLongDescriptionChange}
                                                            modules={{
                                                                toolbar: [
                                                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                                    ['bold', 'italic', 'underline'],
                                                                    [{ 'align': [] }],
                                                                    ['link', 'image'],
                                                                    ['clean'],
                                                                ],
                                                            }}
                                                            formats={[
                                                                'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline',
                                                                'align', 'link', 'image',
                                                            ]}
                                                            style={{ height: '200px' }} // Optional: Control the editor height
                                                        />
                                                        {errors.long_description && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errors.long_description}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    {/* Card for SEO */}
                                    <div className="card shadow mb-4">
                                        <div className="card-header bg-success text-white">SEO Settings</div>
                                        <div className="card-body">
                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group controlId="formMetaTitle">
                                                        <Form.Label>Meta Title</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="meta_title"
                                                            value={formData.meta_title}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.meta_title}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.meta_title}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="formMetaDescription">
                                                        <Form.Label>Meta Description</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="meta_description"
                                                            value={formData.meta_description}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.meta_description}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.meta_description}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group controlId="formMetaKeywords">
                                                        <Form.Label>Meta Keywords</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="meta_keywords"
                                                            value={formData.meta_keywords}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.meta_keywords}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.meta_keywords}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="formFocusKeyword">
                                                        <Form.Label>Focus Keyword</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="focus_keyword"
                                                            value={formData.focus_keyword}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.focus_keyword}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.focus_keyword}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    <Row className="mb-3 float-right">
                                        <Col md={3}></Col>
                                        <Col md={2}>
                                            <Link to="/product-list" className="btn btn-secondary pull-right">
                                                Back To Product
                                            </Link>
                                        </Col>
                                        <Col md={3}>
                                            {/* <Button variant="primary" type="submit">
                                                Submit Product
                                            </Button> */}
                                            <button className="btn btn-primary btn-rounded btn-block" type="submit" disabled={isButtonDisabled}>
                                                {isLoading ? 'Product Adding...' : 'Add Product'}
                                            </button>
                                        </Col>
                                    </Row>
                                </Form>
                                <br />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );



}
