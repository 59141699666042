import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { BannerContext } from '../../store/BannerContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/banner.css';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// import 'bootstrap/dist/css/bootstrap.min.css';

export default function ListBanner() {
    const [banner, setBanner] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage] = useState(10); // Change this value as needed
    const [searchQuery, setSearchQuery] = useState('');
    const { getBanner, deleteBanner, bannerStatus, updateBannerSort } = useContext(BannerContext);

    // Modal state management
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalAction, setModalAction] = useState('');
    const [confirmItem, setConfirmItem] = useState(null);

    // Get all data
    async function getAPIData(page) {
        const response = await getBanner(page, itemsPerPage, searchQuery);
        if (response.result === "Done") {
            setBanner(response.data.docs);
            setTotalPages(response.data.totalPages);
        } else {
            alert(response.message);
        }
    }

    // Handle delete action
    const handleDelete = (item) => {
        setConfirmItem(item);
        setModalAction('delete');
        setShowConfirmModal(true);
    };

    const confirmDelete = async () => {
        if (confirmItem) {
            await deleteBanner({ _id: confirmItem._id });
            getAPIData(currentPage);
            setShowConfirmModal(false);
            toast.success('Banner deleted successfully', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    // Handle status change
    const handleStatusChange = (item) => {
        setConfirmItem(item);
        setModalAction('status');
        setShowConfirmModal(true);
    };

    const confirmStatusChange = async () => {
        if (confirmItem) {
            const newStatus = confirmItem.is_active === 1 ? 0 : 1;
            await bannerStatus(confirmItem._id, newStatus);
            getAPIData(currentPage);
            setShowConfirmModal(false);
            toast.success('Banner status changed successfully', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    // Pagination
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    // Search
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        getAPIData(currentPage);
    };
    // Handle Drag and Drop
    const handleDragEnd = async (result) => {
        if (!result.destination) return; // If dropped outside

        const reorderedBanners = Array.from(banner); // Create a new array from current banners
        const [movedBanner] = reorderedBanners.splice(result.source.index, 1); // Remove the dragged banner
        reorderedBanners.splice(result.destination.index, 0, movedBanner); // Insert it at the new position

        // Update sort_no for each banner according to the new position
        reorderedBanners.forEach((banner, index) => {
            banner.sort_no = index + 1; // Assuming sort_no is 1-based
        });

        setBanner(reorderedBanners); // Update state with the new order

        // Now update the backend with the new order
        try {
            await updateBannerSort(reorderedBanners); // Assuming you have an API to handle this
            toast.success('Banner Position updated!', {
                position: "top-right",
                autoClose: 3000,
            });
        } catch (error) {
            toast.error('Failed to update banner order.', {
                position: "top-right",
                autoClose: 3000,
            });
        }
    };


    useEffect(() => {
        getAPIData(currentPage);
        const toastr = localStorage.getItem('toast');
        if (toastr) {
            toast.success(toastr, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            localStorage.removeItem('toast');
        }
    }, [currentPage]);

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <ToastContainer />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>Banner - List</h3>
                                </div>
                                <div className="flexbox mb-4">
                                    <div className="input-group-icon input-group-icon-left mr-3">
                                        <br />
                                        <form onSubmit={handleSearchSubmit} className="search-container">
                                            <input className="form-control form-control-rounded form-control-solid" id="key-search"
                                                type="text" placeholder="Search Banner..." value={searchQuery}
                                                onChange={handleSearchChange} />
                                        </form>
                                    </div>
                                </div>
                                <Link to="/banner-add" className="btn btn-primary pull-right">Add New Record</Link>
                            </div>
                            <div className="ibox-body">

                                <div className="table-responsive row">
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Droppable droppableId="bannerTable">
                                            {(provided) => (
                                                <table
                                                    className="table table-bordered table-hover text-center"
                                                    id="datatable"
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    <thead className="thead-default thead-lg">
                                                        <tr>
                                                            <th>Sr No</th>
                                                            <th>Image</th>
                                                            <th>Banner Title</th>
                                                            <th>Banner URL</th>
                                                            <th>Description</th>
                                                            <th>Sort</th>
                                                            <th>Status</th>
                                                            <th className="no-sort">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {banner.map((item, index) => (
                                                            <Draggable
                                                                key={item._id}
                                                                draggableId={item._id}
                                                                index={index}
                                                            >
                                                                {(provided) => (
                                                                    <tr
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                                        <td><img src={item.image} width="90px" height="70px" alt="Banner" /></td>                                                                        <td>{item.banner_title}</td>
                                                                        <td>{item.banner_url}</td>
                                                                        <td>{item.description}</td>
                                                                        <td>{item.sort_no}</td>
                                                                        <td>
                                                                            {item.is_active === 1 ? (
                                                                                <span className="badge badge-success">Active</span>
                                                                            ) : (
                                                                                <span className="badge badge-danger">Inactive</span>
                                                                            )}
                                                                        </td>
                                                                        <td className="text-center">

                                                                            <Link
                                                                                to={`/edit-banner/${item._id}`}
                                                                                className="badge badge-primary badge-pill"
                                                                                style={{
                                                                                    display: 'block',
                                                                                    marginBottom: '5px',
                                                                                    cursor: 'pointer',
                                                                                    textDecoration: 'none'
                                                                                }}
                                                                            >
                                                                                Edit Banner
                                                                            </Link>

                                                                            {item.is_active === 1 ? (
                                                                                <span className="badge bg-warning text-dark badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleStatusChange(item)}>
                                                                                    Deactivate Status
                                                                                </span>
                                                                            ) : (
                                                                                <span className="badge bg-secondary badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleStatusChange(item)}>
                                                                                    Activate Status
                                                                                </span>
                                                                            )}
                                                                            <span className="badge badge-danger badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleDelete(item)}>
                                                                                Delete Banner
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </tbody>
                                                </table>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                                <div className="pagination">
                                    <button className="btn btn-primary" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                    <span className="pagination-info">{`Page ${currentPage} of ${totalPages}`}</span>
                                    <button className="btn btn-primary" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

            {/* Modal for Confirmation */}
            <div className={`modal fade ${showConfirmModal ? 'show d-block' : ''}`} tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{modalAction === 'delete' ? 'Confirm Banner Deletion' : 'Confirm Status Update'}</h5>
                            <button type="button" className="btn-close" onClick={() => setShowConfirmModal(false)}></button>
                        </div>
                        <div className="modal-body">
                            <p>{modalAction === 'delete' ? 'Are you sure you want to delete this banner?' : 'Are you sure you want to update the status?'}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmModal(false)}>Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={modalAction === 'delete' ? confirmDelete : confirmStatusChange}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
